import {
  type RouteObject
} from 'react-router-dom';
import AccountDashboardModulesPage from '@/account/dashboard/Modules';
import AccountSubscriptionPlanPage from '@/account/subscription/Plan';
import AccountSubscriptionPlanManagementPage from '@/account/subscription/PlanManagement';
import AccountAccessAdminUserPage from '@/account/access/AdminUser';
import AccountAccessTeamPage from '@/account/access/Team';
import AccountAccessTeamInfoPage from '@/account/access/TeamInfo';
import AccountSetupEventPage from '@/account/setup/Event';
import AccountModuleBillPage from '@/account/subscription/Payment';
import AccountLoginLoginEmailPage from '@/account/login/LoginEmail';
import AccountLoginChooseTenantPage from '@/account/login/ChooseTenant';
import AccountManageAcceptInvitePage from 'src/account/manage/AcceptInvite';
import AccountManageAccountSettingsPage from 'src/account/manage/AccountSettings';
import AccountManageResetPasswordPage from 'src/account/manage/ResetPassword';
import AccountManageSetPasswordPage from 'src/account/manage/SetPassword';
import AccountDashboardModuleRedirectionErrorPage from 'src/account/dashboard/ModuleRedirectionError';
import AdminAccess from '@/account/component/AdminAccess';
import AccountLoginVerifyEmailPasscodePage from '@/account/login/VerifyEmailPasscode';
import AccountSetupTenantPage from '@/account/setup/Tenant';

// All the route names should start with Account to avoid conflicts with other modules (e.g. registration).
export interface AccountRouteParams {
  AccountDashboardModules: {
    tenantCode: string
  }
  AccountSubscriptionPlan: {
    tenantCode: string
  }
  AccountSubscriptionPlanManagement: {
    tenantCode: string
  }
  AccountModuleBill: {
    tenantCode: string
  }
  AccountSetupEvent: {
    tenantCode: string
  }
  AccountAccessAdminUser: {
    tenantCode: string
  }
  AccountAccessTeam: {
    tenantCode: string
  }
  AccountAccessTeamInfo: {
    tenantCode: string
    teamId: string
  }
  AccountLoginLoginEmail: {
  }
  AccountLoginChooseTenant: {
  }
  AccountManageAcceptInvite: {
    tenantCode: string
  }
  AccountManageAccountSettings: {
    tenantCode: string
  }
  AccountManageResetPassword: {
    tenantCode: string
  }
  AccountManageSetPassword: {
    tenantCode: string
  }
  AccountDashboardModuleRedirectionError: {
    tenantCode: string
  }
  AccountLoginVerifyEmailPasscode: {
  }
  AccountSetupTenant: {
    tenantCode: string
  }
}

export type AccountRouteName = keyof AccountRouteParams;

export const accountRoutes: Record<AccountRouteName, RouteObject> = {
  AccountDashboardModules: {
    path: '/account/:tenantCode/dashboard/modules',
    element: (<AdminAccess><AccountDashboardModulesPage /></AdminAccess>)
  },
  AccountSubscriptionPlan: {
    path: '/account/:tenantCode/subscription/plan',
    element: (<AdminAccess><AccountSubscriptionPlanPage /></AdminAccess>)
  },
  AccountSubscriptionPlanManagement: {
    path: '/account/:tenantCode/subscription/plan-management',
    element: (<AdminAccess><AccountSubscriptionPlanManagementPage /></AdminAccess>)
  },
  AccountModuleBill: {
    path: '/account/:tenantCode/subscription/payment',
    element: (<AdminAccess><AccountModuleBillPage /></AdminAccess>)
  },
  AccountSetupEvent: {
    path: '/account/:tenantCode/manage/event',
    element: (<AdminAccess><AccountSetupEventPage /></AdminAccess>)
  },
  AccountAccessAdminUser: {
    path: '/account/:tenantCode/access/admin-user',
    element: (<AdminAccess><AccountAccessAdminUserPage /></AdminAccess>)
  },
  AccountAccessTeam: {
    path: '/account/:tenantCode/access/team',
    element: (<AdminAccess><AccountAccessTeamPage /></AdminAccess>)
  },
  AccountAccessTeamInfo: {
    path: '/account/:tenantCode/access/team/:teamId',
    element: (<AdminAccess><AccountAccessTeamInfoPage /></AdminAccess>)
  },
  AccountLoginLoginEmail: {
    path: '/account/login-email',
    element: (<AccountLoginLoginEmailPage />)
  },
  AccountLoginChooseTenant: {
    path: '/account/choose-tenant',
    element: (<AccountLoginChooseTenantPage />)
  },
  AccountManageAcceptInvite: {
    path: '/account/:tenantCode/manage/accept-invite',
    element: (<AccountManageAcceptInvitePage />)
  },
  AccountManageAccountSettings: {
    path: '/account/:tenantCode/manage/account-settings',
    element: (<AdminAccess><AccountManageAccountSettingsPage /></AdminAccess>)
  },
  AccountManageSetPassword: {
    path: '/account/:tenantCode/manage/set-password',
    element: (<AdminAccess><AccountManageSetPasswordPage /></AdminAccess>)
  },
  AccountManageResetPassword: {
    path: '/account/manage/reset-password',
    element: (<AccountManageResetPasswordPage />)
  },
  AccountDashboardModuleRedirectionError: {
    path: '/account/:tenantCode/dashboard/module-redirection-error',
    element: (<AdminAccess><AccountDashboardModuleRedirectionErrorPage /></AdminAccess>)
  },
  AccountLoginVerifyEmailPasscode: {
    path: '/account/verify-email-passcode',
    element: (<AccountLoginVerifyEmailPasscodePage />),
  },
  AccountSetupTenant: {
    path: '/account/:tenantCode/manage/tenant',
    element: (<AdminAccess><AccountSetupTenantPage /></AdminAccess>)
  },
};
