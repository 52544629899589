import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps, UiIconPlusCircle } from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import TenantForm from './TenantForm';

export interface NewTenantRowProps extends UiHStackProps {}

const NewTenantRow: FC<NewTenantRowProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle} variant={'large'}>Add tenant</BaseButtonIconText>
      <TenantForm
        tenant={null}
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={onToggle}
      />
    </>
  );
};

export default NewTenantRow;
