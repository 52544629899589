import { type FC, useState, useCallback, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, type FormikProps, type FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { UiStack } from '@/lib/ui';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';

import { type ApiResponseSingle } from '@/api/tenantClient';
import { type EventWebsiteTenantUpdateRequest, type EventWebsiteTenant, updateEventWebsiteTenant, createEventWebsiteTenant, type EventWebsiteTenantNewRequest, tenantListQueryKey } from '@/api/account/tenant';

import formLayouts from './formLayout';
import TenantDetailCard from './TenantDetailCard';
import { toBase64, useGlobalToast } from '@/lib/util';
import { validateFile } from '../../Event/EventForm';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface TenantFormData {
  name: string
  createdAt: string
  updatedAt: string
  blackLogo: FileOrUrl[]
  favicon: FileOrUrl[]
  metaLogo: FileOrUrl[]
  appLogo: FileOrUrl[]
  headerBannerImage: FileOrUrl[]
  phone: string
  addressStreet1: string
  addressCity: string
  addressPostCode: string
  addressCountry: string
  active: string
  registrationEnabled: string
  shortUserRegistration: string
  gdprCompliance: string
  perEventRegistration: string
  eventSitemap: string
  smsNotifications: string
  dashboard: string
  ivsEnabled: string
  alertOnMailUnsubscribe: string
  featureFlagEmailBuilder: string
  samlSsoEnabled: string
}

const emptyFormData: TenantFormData = {
  name: '',
  createdAt: '',
  updatedAt: '',
  blackLogo: [],
  favicon: [],
  metaLogo: [],
  appLogo: [],
  headerBannerImage: [],
  phone: '',
  addressStreet1: '',
  addressCity: '',
  addressPostCode: '',
  addressCountry: '',
  active: 'false',
  registrationEnabled: 'false',
  shortUserRegistration: 'false',
  gdprCompliance: 'false',
  perEventRegistration: 'false',
  eventSitemap: 'false',
  smsNotifications: 'false',
  dashboard: 'false',
  ivsEnabled: 'false',
  alertOnMailUnsubscribe: 'false',
  featureFlagEmailBuilder: 'false',
  samlSsoEnabled: 'false',
};

interface TenantFormProps {
  tenant: EventWebsiteTenant | null
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name is too short (minimum is 3 characters)'),
  shortName: Yup.string().required('Tenant Initials'),
  businessNumberLabel: Yup.string().required('Tax Identifier/Name is required.'),
  businessNumber: Yup.string().required('Company Business Number is required.'),
  websiteUrl: Yup.string().required('Website URL is required.').url('Invalid URL'),
  invoiceName: Yup.string().required('Invoice name is required.'),
  addressStreet1: Yup.string().required('Address street 1 is required.'),
  addressStreet2: Yup.string().required('Address street 2 is required.'),
  addressCity: Yup.string().required('City is required.'),
  addressState: Yup.string().required('State is required.'),
  addressPostCode: Yup.string().required('Post code is required.'),
  addressCountry: Yup.string().required('Country is required.'),
  metaTitle: Yup.string().required('Meta title is required.'),
  metaDescription: Yup.string().required('Meta description is required.'),
  metaKeywords: Yup.string().required('Meta keywords is required.'),
  primaryColour: Yup.string().required('Primary colour is required.'),
  secondaryColour: Yup.string().required('Secondary colour is required.'),
  metaLogo: Yup.mixed().required('Meta logo is required.').test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpRegistrationBanner', false); }
  ),
  appLogo: Yup.mixed().nullable().required('App logo is required.').test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpRegistrationBanner', false); }
  ),
});

const TenantForm: FC<TenantFormProps> = ({
  tenant,
  onClose,
  isVisible,
  onSaveSuccess,
}) => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<TenantFormData>>(null);
  const { tenantCode } = useTenantRoute();
  const scrollErrorRef = useRef<HTMLDivElement>(null);
  const { showToast } = useGlobalToast();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { reportToGlobal } = useApiErrorHandler();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate, isLoading } = useMutation<ApiResponseSingle<EventWebsiteTenant>, Error, EventWebsiteTenantUpdateRequest>(
    async (data: EventWebsiteTenantUpdateRequest) => {
      return await updateEventWebsiteTenant(createTenantAdminApiRequest)(data);
    },
    {
      onSuccess: (data) => {
        if ((data?.errors?.length ?? 0) > 0) {
          setSaveErrors([...data.errors ?? []]);
          scrollErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        showToast.success('Tenant updated successfully');
        void queryClient.invalidateQueries({ queryKey: [tenantListQueryKey, { tenantCode }] });
        onClose();
      },
      onError: (error: Error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to save the note.']);
      }
    }
  );

  const { mutate: createTenantMutation, isLoading: isCreateLoading } = useMutation<ApiResponseSingle<EventWebsiteTenant>, Error, EventWebsiteTenantNewRequest>(
    async (data: EventWebsiteTenantNewRequest) => {
      return await createEventWebsiteTenant(createTenantAdminApiRequest)(data);
    },
    {
      onSuccess: async (data) => {
        if ((data?.errors?.length ?? 0) > 0) {
          setSaveErrors([...data.errors ?? []]);
          scrollErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        void queryClient.invalidateQueries({ queryKey: [tenantListQueryKey, { tenantCode }] });
        onSaveSuccess();
        if (formRef.current) {
          formRef.current?.resetForm();
        }
        showToast.success('Tenant created successfully');
        setSaveErrors([]);
        onClose();
      },
      onError: (error: Error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to save the note.']);
      }
    }
  );

  const getInitValues = useCallback(() => {
    if (tenant) {
      const tenantImage = {
        blackLogo: tenant?.blackLogo ? [tenant.blackLogo] : [],
        favicon: tenant?.favicon ? [tenant.favicon] : [],
        metaLogo: tenant?.metaLogo ? [tenant.metaLogo] : [],
        appLogo: tenant?.appLogo ? [tenant.appLogo] : [],
        headerBannerImage: tenant?.headerBannerImage ? [tenant.headerBannerImage] : [],
      };
      return {
        ...tenant,
        ...tenantImage,
        name: tenant?.name ?? '',
        createdAt: tenant?.createdAt ?? '',
        updatedAt: tenant?.updatedAt ?? '',
        phone: tenant?.phone ?? '',
        addressStreet1: tenant?.addressStreet1 ?? '',
        addressCity: tenant?.addressCity ?? '',
        addressPostCode: tenant?.addressPostCode ?? '',
        addressCountry: tenant?.addressCountry ?? '',
        active: tenant?.active ? 'true' : 'false',
        registrationEnabled: tenant?.registrationEnabled ? 'true' : 'false',
        shortUserRegistration: tenant?.shortUserRegistration ? 'true' : 'false',
        gdprCompliance: tenant?.gdprCompliance ? 'true' : 'false',
        perEventRegistration: tenant?.perEventRegistration ? 'true' : 'false',
        eventSitemap: tenant?.eventSitemap ? 'true' : 'false',
        smsNotifications: tenant?.smsNotifications ? 'true' : 'false',
        dashboard: tenant?.dashboard ? 'true' : 'false',
        ivsEnabled: tenant?.ivsEnabled ? 'true' : 'false',
        alertOnMailUnsubscribe: tenant?.alertOnMailUnsubscribe ? 'true' : 'false',
        featureFlagEmailBuilder: tenant?.featureFlagEmailBuilder ? 'true' : 'false',
        samlSsoEnabled: tenant?.samlSsoEnabled ? 'true' : 'false',
      } satisfies Partial<TenantFormData>;
    }

    return emptyFormData;
  }, [tenant]);

  const onSubmit = useCallback(async (
    values: TenantFormData,
    { setSubmitting, resetForm }: FormikHelpers<TenantFormData>
  ) => {
    const tenantImage: {
      tmpBlackLogo?: string
      tmpFavicon?: string
      tmpMetaLogo?: string
      tmpAppLogo?: string
      tmpHeaderBannerImage?: string
    } = {
      ...(tenant?.blackLogo !== values?.blackLogo?.[0] && { tmpBlackLogo: values.blackLogo[0] instanceof File ? await toBase64(values.blackLogo[0]) : values.blackLogo[0] }),
      ...(tenant?.favicon !== values?.favicon?.[0] && { tmpFavicon: values.favicon[0] instanceof File ? await toBase64(values?.favicon?.[0]) : values.favicon[0] }),
      ...(tenant?.metaLogo !== values?.metaLogo?.[0] && { tmpMetaLogo: values.metaLogo[0] instanceof File ? await toBase64(values?.metaLogo?.[0]) : values.metaLogo[0] }),
      ...(tenant?.appLogo !== values?.appLogo?.[0] && { tmpAppLogo: values.appLogo[0] instanceof File ? await toBase64(values?.appLogo?.[0]) : values.appLogo[0] }),
      ...(tenant?.headerBannerImage !== values?.headerBannerImage?.[0] && { tmpHeaderBannerImage: values.headerBannerImage[0] instanceof File ? await toBase64(values.headerBannerImage[0]) : values.headerBannerImage[0] }),
    };

    const ommitedTenant = omit(tenant, ['blackLogo', 'favicon', 'metaLogo', 'appLogo', 'headerBannerImage']);
    const omittedValues = omit(values, ['blackLogo', 'favicon', 'metaLogo', 'appLogo', 'headerBannerImage']);

    if (tenant?.id) {
      mutate({ tenant: { ...ommitedTenant, ...omittedValues, ...tenantImage } });
    } else {
      createTenantMutation({ tenant: { ...omittedValues, ...tenantImage } });
    }
  }, [createTenantMutation, mutate, tenant]);

  return (
    <Formik<TenantFormData>
      innerRef={formRef}
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {(form) => {
        return <BaseFormDrawer
          isOpen={isVisible}
          onClose={() => {
            setSaveErrors([]);
            onClose();
          }}
          title={'Tenant'}
          size={'xl'}
          isLoading={isLoading || isCreateLoading}
        >
          <div ref={scrollErrorRef} />
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <BaseFormFieldGroup>
            {Object.values(formLayouts).map((formLayoutContainer) => {
              return (
                <TenantDetailCard
                  form={form as unknown as FormikProps<TenantFormData & Record<string, string | number | boolean>>}
                  key={formLayoutContainer.key}
                  title={formLayoutContainer.label}
                  inputFields={formLayoutContainer.formLayout}
                />
              );
            })}
          </BaseFormFieldGroup>
        </BaseFormDrawer>;
      }}
    </Formik>
  );
};

export default TenantForm;
