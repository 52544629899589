import { type FC } from 'react';
import {
  Buildings
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconBuildingsProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconBuildings: FC<UiIconBuildingsProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Buildings}
      {...props}
    />
  );
};
