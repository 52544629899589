import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconPlugs } from '@/lib/ui';

export interface IntegrationCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
  showDescription?: boolean
}

const IntegrationCard: FC<IntegrationCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false,
  showDescription = true
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/integrations.jpeg'}
      name={'Integrations'}
      icon={(<UiIconPlugs size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      description={showDescription ? 'Enable and manage integrations with other systems' : undefined}
      isSelected={isSelected}
      // bgColor={'red'}
      navLink={navLink}
    />
  );
};
export default IntegrationCard;
