import { type FC } from 'react';
import { UiHStack, uiStyles, UiText, UiSimpleGrid, UiStack } from '@/lib/ui';
import { type EventWebsiteTenant } from '@/api/account/tenant';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { useDisclosure } from '@chakra-ui/react';
import TenantForm from './TenantForm';
import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import DeletionConfirmation from './DeletionConfirmation';

interface TenantRowProps {
  tenant: EventWebsiteTenant
  onDelete: () => void
  setSelectedTenant: (tenant: EventWebsiteTenant) => void
  isLoading: boolean
}

const TenantRow: FC<TenantRowProps> = ({ tenant, onDelete, isLoading, setSelectedTenant, ...props }) => {
  const { isOpen: isDeleteOpen, onToggle: onToggleDelete } = useDisclosure();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <UiHStack p={4}
        px={8}
        // px={8}
        flexGrow={1}
        spacing={4}
        bgColor={'#fff'}
        justifyContent={'space-between'}
        // bgColor={isSelected ? 'primary.50' : 'transparent'}
        {...uiStyles.hoverFlex({ bgColor: 'gray.50' })}
        {...props}

      >
        <UiSimpleGrid columns={4} spacing={4} flexGrow={1}>
          <UiHStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Tenant ID: {tenant.id}</UiText>
            </UiStack>
          </UiHStack>
        </UiSimpleGrid>
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Account, PermissionAction.Write]]}>
          <BaseButtonPlainIconEdit
            onClick={onToggle}
          />
          <BaseButtonPlainIconDelete onClick={() => {
            setSelectedTenant(tenant);
            return onToggleDelete();
          }} />
        </BaseAclContainer>
        <DeletionConfirmation isOpen={isDeleteOpen} name={tenant.name} onClose={onToggleDelete} onConfirm={onDelete} />
      </UiHStack>
      <TenantForm tenant={tenant} onClose={onToggle} onSaveSuccess={() => { }} isVisible={isOpen} />
    </>
  );
};

export default TenantRow;
