import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { loadRefundsForTable, refundTableQueryKey, type RefundTableRow } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect } from 'react';
import Datatable from './Datatable';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type MRT_SortingState, type MRT_ColumnFiltersState, type MRT_PaginationState } from 'mantine-react-table';
import { useLocalStorage } from '@uidotdev/usehooks';

interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
}

const useGetRefunds = ({ filters, sorting, pagination, search }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  return useQuery<ApiResponse<RefundTableRow>, Error>({
    queryKey: [refundTableQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<RefundTableRow>> => {
      return await loadRefundsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: !isTenantLoading,
  });
};

const LOCAL_TABLE_STORAGE_KEY = 'refund_table';

const RefundTable = () => {
  const { tenantCode } = useRegisterRoute();
  const [filters, setFilters] = useLocalStorage<MRT_ColumnFiltersState>(`${LOCAL_TABLE_STORAGE_KEY}_filters_${tenantCode}`, []);
  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(`${LOCAL_TABLE_STORAGE_KEY}_sorting_${tenantCode}`, []);
  const [search, setSearch] = useLocalStorage<string>(`${LOCAL_TABLE_STORAGE_KEY}_search_${tenantCode}`, '');
  const [pagination, setPagination] = useLocalStorage<MRT_PaginationState>(`${LOCAL_TABLE_STORAGE_KEY}_pagination_${tenantCode}`, { pageIndex: 0, pageSize: 10 });
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, isError, error, isFetching, refetch } = useGetRefunds({ filters, sorting, pagination, search, setPagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner />
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <Datatable
        data={data.items}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={data.pagination!.total}
        delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
        sessionSelectOptions={data.selectOptions!.sessionSelectOptions}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No Refunds
      </BaseMessageBarInfo>
    );
  }
};

export default RefundTable;
