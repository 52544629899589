import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect } from 'react';
import Datatable from './Datatable';
import { groupRegistrationQueryKey, type IGroupRegistrationTableRow, loadGroupRegistrationsForTable } from '@/api/registration/groupRegistration';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useLocalStorage } from '@uidotdev/usehooks';
import { type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState, type MRT_VisibilityState } from 'mantine-react-table';

export interface SerializedRegistrationTableRow extends Omit<IGroupRegistrationTableRow, 'createdAt' | 'confirmDelegateTypeAt'> {
  createdAt: Date
}

interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
}

const initialVisibilityState: MRT_VisibilityState = {
  id: false,
  uuid: false,
  'delegateType.name': false,
  'ticket.function': false,
  'ticket.workshop': false,
  'ticket.tour': false,
  paid: false,
  complete: false,
  createdAt: false,
  confirmDelegateTypeAt: false,
  'attendeeCategory.name': false
};

const useGetGroupRegistrations = ({ filters, sorting, pagination, search }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  return useQuery<ApiResponse<IGroupRegistrationTableRow>, Error>({
    queryKey: [groupRegistrationQueryKey, pagination, filters, sorting, search] as const,
    queryFn: async (): Promise<ApiResponse<IGroupRegistrationTableRow>> => {
      return await loadGroupRegistrationsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        type: 'all',
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: isLoaded,
  });
};

const LOCAL_TABLE_STORAGE_KEY = 'group_registration_table';

const RegistrationTable = () => {
  const { tenantCode } = useRegisterRoute();
  const [filters, setFilters] = useLocalStorage<MRT_ColumnFiltersState>(`${LOCAL_TABLE_STORAGE_KEY}_filters_${tenantCode}`, []);
  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(`${LOCAL_TABLE_STORAGE_KEY}_sorting_${tenantCode}`, []);
  const [search, setSearch] = useLocalStorage<string>(`${LOCAL_TABLE_STORAGE_KEY}_search_${tenantCode}`, '');
  const [pagination, setPagination] = useLocalStorage<MRT_PaginationState>(`${LOCAL_TABLE_STORAGE_KEY}_pagination_${tenantCode}`, { pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>(`${LOCAL_TABLE_STORAGE_KEY}_columns_${tenantCode}`, initialVisibilityState);
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, isError, error, isFetching, refetch } = useGetGroupRegistrations({ filters, sorting, pagination, search, setPagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner />
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <Datatable
        data={data.items.map((registration) => {
          return {
            ...registration,
            createdAt: new Date(registration.createdAt),
            confirmDelegateTypeAt: registration.confirmDelegateTypeAt === null ? null : new Date(registration.confirmDelegateTypeAt)
          };
        })}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        setPagination={setPagination}
        rowCount={data.pagination!.total}
        delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
        booleanSelectOptions={data.selectOptions!.booleanSelectOptions}
        statusSelectOptions={data.selectOptions!.statusSelectOptions}
        tourTicketsSelectOptions={data.selectOptions!.tourTicketsSelectOptions}
        workshopTicketsSelectOptions={data.selectOptions!.workshopTicketsSelectOptions}
        functionTicketsSelectOptions={data.selectOptions!.functionTicketsSelectOptions}
        attendeeCategorySelectOptions={data.selectOptions!.attendeeCategorySelectOptions}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No registrations
      </BaseMessageBarInfo>
    );
  }
};

export default RegistrationTable;
