import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { type UiBoxProps, UiButton, UiHStack, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { subscriptionPackageLabels, SubscriptionState } from '@/api/constant/subscription';
import Status from '@/account/subscription/Plan/Status';
dayjs.extend(customParseFormat);

export interface SummaryProps extends UiBoxProps {
  subscription: account.Subscription
}

const PlanSummary: FC<SummaryProps> = ({
  subscription
}) => {
  if (!subscription.plan) {
    return (
      <BaseMessageBarError>Failed to load the summary</BaseMessageBarError>
    );
  }

  return (
    <UiHStack
      spacing={4}
      p={8}
      py={6}
      bgColor={'#fff'}
      flexGrow={1}
      justifyContent={'space-between'}
      shadow={uiStyles.cardShadow}
      borderRadius={uiStyles.borderRadius}
    >
      <UiSimpleGrid spacing={0} columns={4} flexGrow={1}>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Status</UiText>
          {subscription.plan.state === SubscriptionState.Active ? (
            <Status subscriptionState={subscription.plan.state}/>
          ) : (
            <UiText fontWeight={400}>-</UiText>
          )}
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Package</UiText>
          {subscription.plan.state === SubscriptionState.Active ? (
            <UiText fontWeight={400}>{subscriptionPackageLabels[subscription.plan.package]}</UiText>
          ) : (
            <UiText fontWeight={400}>-</UiText>
          )}
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Service pack</UiText>
          <UiText fontWeight={400}>{(subscription.plan.servicePack.hours ?? 0) - (subscription.plan.servicePack.usedHours ?? 0)} / {(subscription.plan.servicePack.hours ?? 0)} hours</UiText>
        </UiStack>
        {(subscription.plan.state === SubscriptionState.Active && subscription.plan.to) && (
          <UiStack spacing={2}>
            <UiText variant={'body1'} color={'text.secondary'}>Next renew</UiText>
            <UiText fontWeight={400}>{dayjs.unix(subscription.plan.to).format('DD MMMM YYYY')}</UiText>
          </UiStack>
        )}
      </UiSimpleGrid>
    </UiHStack>
  );
};

export default PlanSummary;
