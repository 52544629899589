
import { type FC } from 'react';
import {
  UiBox,
  UiHStack,
  UiHStackProps,
  UiIconArrowSquareOut,
  UiIconDotsThree,
  UiIconDotsThreeCircle,
  UiIconEye,
  UiIconList,
  UiIconListChecks,
  UiSimpleGrid,
  UiStack,
  UiText,
} from '@/lib/ui';
import { account } from '@/api';
import dayjs from 'dayjs';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import { calculateServicePackPrice } from '@/account/subscription/utils';

export interface TermListProps extends UiHStackProps {
  term: account.SubscriptionTermRecord
  termBill?: account.SubscriptionBillData
}

const Term: FC<TermListProps> = ({
  term,
  termBill,
  ...props
}) => {
  if (!term.model || !term.model.plan) {
    return (
      <BaseMessageBarError>Failed to load the term</BaseMessageBarError>
    );
  }

  return (
    <UiHStack
      spacing={4}
      p={8}
      py={6}
      bgColor={'#fff'}
      flexGrow={1}
      justifyContent={'space-between'}
      // shadow={uiStyles.cardShadow}
      // borderRadius={uiStyles.borderRadius}
      {...props}
    >
      <UiSimpleGrid spacing={0} columns={4} flexGrow={1}>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Start</UiText>
          <UiText fontWeight={400}>{dayjs.unix(term.model.plan.from).format('DD/MM/YYYY')}</UiText>
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>End</UiText>
          <UiText fontWeight={400}>{dayjs.unix(term.model.plan.to).format('DD/MM/YYYY')}</UiText>
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Add-ons</UiText>
          {!!term.model?.plan?.addons && Array.isArray(term.model.plan.addons) && term.model.plan.addons.length > 0 ? (
            <UiHStack>
              <UiText fontWeight={400}>{term.model.plan.addons.length}</UiText>
              <BaseButtonPlainIcon
                label={'View add-ons'}
                Icon={UiIconListChecks}
                color={'primary.500'}
              />
            </UiHStack>
          ) : (
            <UiText fontWeight={400}>0</UiText>
          )}
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Bill</UiText>
          {!!termBill ? (
            <UiHStack>
              <PriceDisplay price={termBill.totalAmount / 100}/>
              <BaseButtonPlainIcon
                label={'View invoice'}
                Icon={UiIconDotsThreeCircle}
                color={'primary.500'}
              />
            </UiHStack>
          ) : (
            <UiText fontWeight={400}>-</UiText>
          )}
        </UiStack>
      </UiSimpleGrid>
    </UiHStack>
  );
};

export default Term;
