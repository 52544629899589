import {
  type registration
} from '@/api';
import BaseBlockList from '@/base/BlockList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useMemo, useState, type FC } from 'react';
import { UiHStack, UiVStack } from '@/lib/ui';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import EventRow from './EventRow';
import Paginate from '@/base/Paginate/Paginate';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

dayjs.extend(utc);

export interface EventListProps {
  queryParams: registration.EventListQueryRequest
  searchKey?: string
}

const EventList: FC<EventListProps> = ({
  queryParams,
  searchKey = ''
}) => {
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });
  const { data, isLoading, isError } = useEventListQuery({ pagination, search: searchKey });

  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });
  }, [searchKey]);

  const eventList = useMemo(() => {
    return data?.items ?? [];
  }, [data?.items,]);

  const pageCount = useMemo(() => {
    const total = data?.pagination?.total ?? 0;
    return Math.ceil(total / pagination.pageSize);
  }, [data?.pagination?.total, pagination.pageSize]);

  const currentPage = useMemo(() => {
    const page = data?.pagination?.page;
    if (page) {
      return page - 1;
    }
    return pagination.page;
  }, [data?.pagination?.page, pagination.page]);

  if (isLoading && !data) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (isError) {
    return (
      <BaseMessageBarError>
        Failed to load the event list. Please try to refresh the page.
      </BaseMessageBarError>
    );
  }

  return (
    <UiVStack alignItems={'stretch'} spacing={4}>
      <BaseBlockList>
        {eventList?.map((event) => {
          return (
            <EventRow
              key={event.id}
              event={event}
            />
          );
        })}
      </BaseBlockList>
      <UiHStack justifyContent={'flex-end'}>
        <Paginate
          onPageChange={(value) => {
            setPagination({ ...pagination, pageIndex: value.selected });
          }}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </UiHStack>
    </UiVStack>
  );
};

export default EventList;
