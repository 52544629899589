/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { compact } from 'lodash';

// Pintura Image Editor
import '@pqina/pintura/pintura.css';
import { UiText, UiCenter, UiWarp, uiStyles, UiButtonIcon } from '@/lib/ui';
import ImageSelectItem, { type FileOrUrl } from './ImageSelectItem';
import { Trash } from '@phosphor-icons/react';

interface ImageSelectProps {
  value: FileOrUrl[]
  name: string
  isMultiple?: boolean
  onChange: (value: FileOrUrl[]) => void
  disabled?: boolean
}

const ImageSelect: FC<ImageSelectProps> = ({ value: files, onChange, isMultiple, disabled = false }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, [onChange]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    multiple: isMultiple,
    onDrop
  });

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange(['']);
  };
  return (
    <UiCenter
      {...getRootProps()}
      p={4}
      border='2px'
      _focus={{ borderColor: 'blue.500' }}
      borderRadius={uiStyles.formElementBorderRadius}
      borderColor='gray.300'
      borderStyle="dashed"
      role="group"
      bgColor="white">
      <UiCenter
        hidden={!compact(files).length}
        position="absolute"
        top={1}
        right={1}
        bgColor="#f87171"
        opacity={0}
        borderRadius={10}
        padding={0}
        zIndex={10}
        _groupHover={{ opacity: 1 }}>
        <UiButtonIcon
          size={'sm'}
          colorScheme='#f87171'
          aria-label='Remove'
          pointerEvents="initial"
          icon={<Trash size={20} />}
          onClick={onDelete}
        />
      </UiCenter>
      <input {...getInputProps()} />
      {
        compact(files)?.length ? (
          <UiWarp mt={4} spacing={2}>
            {
              compact(files).map(
                (file, index) => {
                  return <ImageSelectItem
                    key={file instanceof File ? file.name : file}
                    file={file}
                    onUpdate={(output) => {
                      const updatedFiles = [...files];
                      updatedFiles[index] = output;
                      onChange(updatedFiles);
                    }}
                  />;
                }
              )
            }
          </UiWarp>
        ) : (
          <UiText color="gray">Drag 'n' drop some files here, or click to select files</UiText>
        )
      }
    </UiCenter>
  );
};

export default ImageSelect;
