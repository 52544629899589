import { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { type FieldMetadataDate } from './Field';
import BaseFormSelectField from '@/base/Form/SelectField';
import { validateLabelFields } from '@/lib/util';

export interface FieldDateFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataDate) => void
  fieldMetadata?: FieldMetadataDate
}

interface FieldAttributes {
  label: string
  helpText?: string
  isRequired: boolean
}

interface Errors {
  label?: string
  helpText?: string
  isRequired?: string
}

const FieldDateForm: FC<FieldDateFormProps> = ({
  fieldMetadata = undefined,
  onSave
}) => {
  const initValues: FieldAttributes = {
    label: fieldMetadata?.label ?? '',
    helpText: fieldMetadata?.helpText ?? '',
    isRequired: fieldMetadata?.isRequired ?? false
  };

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        const labelError = validateLabelFields(values.label);
        if (labelError) {
          errors.label = labelError;
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<FieldAttributes>
      ) => {
        // setSubmitting(false);
        if (fieldMetadata) {
          onSave({
            ...fieldMetadata,
            ...{
              type: 'date',
              label: values.label,
              helpText: values.helpText,
              isRequired: values.isRequired,
              canDelete: true,
              canEdit: true
            }
          });
        } else {
          onSave({
            id: uuidv4().replaceAll('-', ''),
            type: 'date',
            label: values.label,
            helpText: values.helpText,
            isRequired: values.isRequired,
            canDelete: true,
            canEdit: true
          });
        }
      }}
    >
      <Form
        style={{
          display: 'flex',
          marginTop: 0
        }}
      >
        <UiStack flexGrow={1}>
          <BaseFormFieldGroup>
            <BaseFormInputField
              name={'label'}
              label={'Field label'}
            />
            <BaseFormInputField
              name={'helpText'}
              label={'Help text'}
              isRequired={false}
              helperText={'This the text you want to show underneath the field to provide some context or examples.'}
            />
            <BaseFormSelectField
              name={'isRequired'}
              label={'Is this field mandatory?'}
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
            />
          </BaseFormFieldGroup>
          <UiStack flexDirection={'row-reverse'} pt={12}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
              Save
            </UiButton>
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default FieldDateForm;
