import { type FC } from 'react';
import {
  UiBox,
  type UiBoxProps, UiHStack, UiIconHeadset, UiIconNavigationArrow,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import AppWayfinderCard from '@/base/App/AppWayfinderCard';
import AILiveCaptioningCard from '@/base/App/AILiveCaptioningCard';
import { UiTooltip } from '@/lib/ui/Tooltip';

export interface AppWayFinderProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const AppWayFinder: FC<AppWayFinderProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.AppWayfinder) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <UiTooltip
            label={'Embedded in-venue wayfinder in the event app'}
            borderRadius={uiStyles.borderRadius}
            p={2}
            px={4}
          >
            <UiBox {...uiStyles.hover}>
              <AppWayfinderCard/>
            </UiBox>
          </UiTooltip>
          {subscription && (
            <PriceDisplay price={subscription.products[SubscriptionProduct.AppWayfinder].monthlyPrice * 3} suffix={' / 3 months'} />
          )}
        </UiHStack>
        {/*<BaseDividerHorizontal height={4} />*/}
        {/*<UiText variant='body1' color={'text.secondary'}>Embedded in-venue wayfinder in the event app</UiText>*/}
      </UiStack>
    </AddonCard>
  );
};

export default AppWayFinder;
