import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { forceCompleteRegistration, ForceCompleteRegistrationRequest, ForceCompleteRegistrationResponse, transferRegistration, TransferRegistrationRequest, TransferRegistrationResponse } from '@/api/admin/registration';
import { orderIdQueryKey, registrationIdQueryKey, registrationTableQueryKey, type RegistrationInfo } from '@/api/registration';
import { ApiResponse, ApiResponseSingle } from '@/api/tenantClient';
import BaseFormInputField from '@/base/Form/InputField';
import { BaseMessageBarError } from '@/base/MessageBar/Error';
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  UiHStack,
  UiStack,
  type UiDrawerProps, uiStyles, UiText,
  UiDrawerFooter,
} from '@/lib/ui';
import { EMAIL_REGEX } from '@/lib/util/regex';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useCallback, useState, type FC } from 'react';
import * as Yup from 'yup';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface RegistrationCompleteDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  registrationId: number
}

const RegistrationCompleteDrawer: FC<RegistrationCompleteDrawerProps> = ({
  isOpen,
  onClose,
  registrationId
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);
  const { mutate: mutateForceComplete, isLoading: isCompleting } =
    useMutation<ApiResponseSingle<ForceCompleteRegistrationResponse>, Error, ForceCompleteRegistrationRequest>({
      mutationFn: async (data: ForceCompleteRegistrationRequest) => {
        return await forceCompleteRegistration(createTenantAdminApiRequest)(data);
      },
      onSuccess: (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setErrors(result?.errors);
        } else {
          setErrors([]);
          void queryClient.invalidateQueries({ queryKey: [ registrationIdQueryKey, {id: registrationId} ]});
          void queryClient.invalidateQueries({ queryKey: [ registrationTableQueryKey]});
          void queryClient.invalidateQueries({ queryKey: [ { profileableType: "Registration", profileableId: registrationId }]});
          onClose();
         }
      },
      onError: (error) => {
        setErrors([error.message ?? 'Failed to force complete the registration.']);
      }
    });

  const onConfirm = useCallback(() => {
    mutateForceComplete({
      registrationId: registrationId
    });
  }, [registrationId, mutateForceComplete]);

  return (
    <UiDrawer
      placement={"bottom"}
      size={"lg"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent p={4}>
        {errors.length > 0 && (
          <UiStack spacing={4} flexGrow={1}>
            {errors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <UiDrawerBody>
          <UiText variant={"title"} fontWeight={400}>
            Please note that forcing completion on a registration may result in unexpected application behaviour.
            <br />
            If the user has not provided form responses, certain features like registration badge printing may not work as expected. Are you sure you want to continue?
          </UiText>
        </UiDrawerBody>
        <UiDrawerFooter>
          <UiHStack spacing={8}>
            <UiButton
              onClick={onClose}
              colorScheme={"gray"}
              px={8}
            >
              No
            </UiButton>
            <UiButton
              colorScheme={"primary"}
              ml={4}
              onClick={onConfirm}
              isLoading={isCompleting}
              px={8}
            >
              Yes
            </UiButton>
          </UiHStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default RegistrationCompleteDrawer;
