import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type Event, infoFormResponseTableQueryKey, loadInfoFormResponseForTable, type InfoFormResponseTableRow } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import { type IPaginationParams, useEventListQuery } from '@/registration/hook/useEventListQuery';
import { useQuery } from '@tanstack/react-query';
import { type OnChangeValue } from 'chakra-react-select';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import { UiSimpleGrid, UiSpinner, UiStack } from '@/lib/ui';
import type React from 'react';
import { useEffect, useState } from 'react';
import Datatable from './Datatable';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState, type MRT_VisibilityState } from 'mantine-react-table';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useLocalStorage } from '@uidotdev/usehooks';

interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
  eventId?: string
  attendeeCategoryId?: string
}

const initialVisibilityState: MRT_VisibilityState = {
  'ticket.function': false,
  'ticket.tour': false,
  'ticket.workshop': false,
  createdAtDate: false,
  createdAtTime: false,
  completedAtDate: false,
  completedAtTime: false
};

const useGetInfoFormResponse = ({ filters, sorting, pagination, search, eventId, attendeeCategoryId }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();

  return useQuery<ApiResponse<InfoFormResponseTableRow>, Error>({
    queryKey: [infoFormResponseTableQueryKey, pagination, filters, sorting, search, eventId, attendeeCategoryId],
    queryFn: async (): Promise<ApiResponse<InfoFormResponseTableRow>> => {
      return await loadInfoFormResponseForTable(createTenantAdminApiRequest)({
        eventId,
        attendeeCategoryId,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: !isTenantLoading,
  });
};

const LOCAL_TABLE_STORAGE_KEY = 'info_form_response_table';

const FormResponseTable = () => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const { tenantCode } = useRegisterRoute();
  const [filters, setFilters] = useLocalStorage<MRT_ColumnFiltersState>(`${LOCAL_TABLE_STORAGE_KEY}_filters_${tenantCode}`, []);
  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(`${LOCAL_TABLE_STORAGE_KEY}_sorting_${tenantCode}`, []);
  const [search, setSearch] = useLocalStorage<string>(`${LOCAL_TABLE_STORAGE_KEY}_search_${tenantCode}`, '');
  const [pagination, setPagination] = useLocalStorage<MRT_PaginationState>(`${LOCAL_TABLE_STORAGE_KEY}_pagination_${tenantCode}`, { pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>(`${LOCAL_TABLE_STORAGE_KEY}_columns_${tenantCode}`, initialVisibilityState);
  const { data: eventList, isFetching: isLoadingEvent } = useEventListQuery({ pagination });
  const [eventOptions, setEventOptions] = useState<SelectElementOption[]>([]);
  const [eventId, setEventId] = useState<string>('');
  const [attendeeCategoryList, setAttendeeCategoryList] = useState<any>([]);
  const [attendeeCategoryId, setAttendeeCategoryId] = useState<string>('');
  const { data, isLoading, refetch, isError, isFetching } = useGetInfoFormResponse({ filters, sorting, pagination, search, setPagination, eventId, attendeeCategoryId });

  useEffect(() => {
    if (eventId) {
      const fetchAttendeeCategoryList = async () => {
        const result = await registration.loadAttendeeCategoryList(createTenantAdminApiRequest)({ eventId });
        setAttendeeCategoryList(result.items[0].map((item: registration.AttendeeCategoryData) => { return { value: item.id.toString(), label: item.name }; }));
        setAttendeeCategoryId('');
      };

      void fetchAttendeeCategoryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (attendeeCategoryId) {
      const fetchData = async () => {
        try {
          await refetch();
        } catch (error) { }
      };

      void fetchData();
    }
  }, [attendeeCategoryId, refetch]);

  const handleChangeEvent = async (option: OnChangeValue<SelectElementOption, false>) => {
    setEventId(option?.value ? `${option?.value}` : '');
  };

  const handleChangeAttendeeCategory = (option: OnChangeValue<SelectElementOption, false>) => {
    setAttendeeCategoryId(option?.value ? `${option?.value}` : '');
  };

  useEffect(() => {
    if (eventList?.items && Array.isArray(eventList?.items)) {
      const options = (eventList?.items ?? []).map((event: Event) => { return { value: event.id.toString(), label: event.name }; });
      setEventOptions([...eventOptions, ...options]);
    }
  }, [eventList]);

  const handleScrollToBottom: ((event: WheelEvent | TouchEvent) => void) | undefined = async () => {
    if (eventList?.pagination?.totalPages && pagination.pageIndex + 1 < eventList?.pagination?.totalPages) {
      setPagination((prev: { pageIndex: number, pageSize: number }) => { return { ...prev, pageIndex: prev.pageIndex + 1 }; });
    }
  };

  return (
    <>
      <UiStack>
        <UiSimpleGrid columns={{ base: 2, '2xl': 4 }} spacing={8} maxW={1600}>
          <BaseSimpleSelectElement
            optionValue={eventId}
            onChange={handleChangeEvent}
            isLoading={isLoadingEvent}
            options={eventOptions}
            placeholder={'Select event'}
            onScrollToBottom={handleScrollToBottom}
          />
          <BaseSimpleSelectElement
            optionValue={attendeeCategoryId}
            onChange={handleChangeAttendeeCategory}
            options={attendeeCategoryList}
            placeholder={'Select attendee group'}
          />
        </UiSimpleGrid>
      </UiStack>
      <BaseDividerHorizontal height={8} />
      {(!data && isLoading && !!attendeeCategoryId) && (
        <BaseLoadingSpinner />
      )}
      {eventId && attendeeCategoryId && data?.items && Array.isArray(data?.items) && (
        <Datatable
          data={data.items.map((FormResponse) => {
            return {
              ...FormResponse,
              createdAt: new Date(FormResponse.createdAt),
              confirmDelegateTypeAt: FormResponse.confirmDelegateTypeAt === null ? null : new Date(FormResponse.confirmDelegateTypeAt)
            };
          })}
          isLoading={isLoading}
          isError={isError}
          isFetching={isFetching}
          refetch={refetch}
          filters={filters}
          setFilters={setFilters}
          sorting={sorting}
          setSorting={setSorting}
          search={search}
          setSearch={setSearch}
          pagination={pagination}
          setPagination={setPagination}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          rowCount={data?.pagination?.total ?? 0}
          personalColumns={data?.infoFormQuestions?.personal}
          additionalColumns={data?.infoFormQuestions?.additional}
          eventId={eventId}
          attendeeCategoryId={attendeeCategoryId}
          statusSelectOptions={data?.selectOptions?.statusSelectOptions ?? []}
          delegateTypeSelectOptions={data?.selectOptions?.delegateTypeSelectOptions ?? []}
          tourTicketsSelectOptions={data?.selectOptions?.tourTicketsSelectOptions ?? []}
          workshopTicketsSelectOptions={data?.selectOptions?.workshopTicketsSelectOptions ?? []}
          functionTicketsSelectOptions={data?.selectOptions?.functionTicketsSelectOptions ?? []}
        />
      )}
    </>
  );
};

export default FormResponseTable;
