import { type FC } from 'react';
import {
  UiBox,
  type UiBoxProps, UiHStack, UiIconClosedCaptioning, UiIconFileArrowUp,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import AbstractCard from '@/base/App/AbstractCard';
import { UiTooltip } from '@/lib/ui/Tooltip';

export interface AbstractManagementProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const AbstractManagement: FC<AbstractManagementProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      // isEnabled={subscription ? getAddonsEnabled(subscription).includes(SubscriptionProduct.AbstractManagement) : false}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.AbstractManagement) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <UiTooltip
            label={'Abstract submission & review'}
            borderRadius={uiStyles.borderRadius}
            p={2}
            px={4}
          >
            <UiBox {...uiStyles.hover}>
              <AbstractCard showDescription={false}/>
            </UiBox>
          </UiTooltip>
          {subscription && (
            <UiStack spacing={0} alignItems={'flex-end'}>
              <UiStack>
                <PriceDisplay price={subscription.products[SubscriptionProduct.AbstractManagement].monthlyPrice * 3} suffix={' / 3 months'} />
              </UiStack>
              <UiHStack>
                <UiText>+</UiText><PriceDisplay price={subscription.products[SubscriptionProduct.AbstractManagement].unitRate} suffix={' / abstract submission'} />
              </UiHStack>
            </UiStack>
          )}
        </UiHStack>
      </UiStack>
    </AddonCard>
  );
};

export default AbstractManagement;
