import { IconButton, type IconButtonProps } from '@chakra-ui/react';
import { type FC } from 'react';

export interface UiButtonIconProps extends IconButtonProps {
  enableHoverTransition?: boolean
};

export const UiButtonIcon: FC<UiButtonIconProps> = ({
  enableHoverTransition = true,
  ...props
}) => {
  const _hoverTransition = enableHoverTransition
    ? {
      transform: 'opacity',
      transitionDuration: '0.4s',
      transitionTimingFunction: 'ease-in-out',
      opacity: 1,
      _hover: {
        opacity: 0.6,
        transform: 'opacity',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease-in-out',
        cursor: 'pointer'
      }
    }
    : {};

  return (
    <IconButton
      {...props}
      {..._hoverTransition}
    />
  );
};
