import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
} from '@/lib/ui';
import TenantForm from './TenantForm';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewTenantProps extends UiHStackProps {
}

const NewTenant: FC<NewTenantProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle} variant={'large'}>Add workspace</BaseButtonIconText>
      <TenantForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewTenant;
