import { type FC, useMemo } from 'react';
import {
  UiHStack,
  UiStack,
  UiText,
  uiStyles, UiSwitch,
} from '@/lib/ui';
import { type account } from '@/api';
import { UiSlider, UiSliderFilledTrack, UiSliderMark, UiSliderThumb, UiSliderTrack } from '@/lib/ui/Slider';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import { calculateServicePackPrice } from '@/account/subscription/utils';

export interface ServicePackEditorProps {
  subscription: account.Subscription
  onHoursUpdate: (hours: number) => void
  onToggle: () => void
}

const ServicePackEditor: FC<ServicePackEditorProps> = ({
  subscription,
  onHoursUpdate,
  onToggle,
}) => {
  const isDisabled = useMemo(
    () => {
      return subscription.plan.servicePack.hours === 0;
    },
    [subscription.plan.servicePack.hours]
  );

  return (
    <UiStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      transition={'all .3s ease'}
      borderRadius={uiStyles.borderRadius}
      // opacity={isDisabled ? 0.3 : 1}
      // flexGrow={1}
      p={8}
      bgColor={'#fff'}
      borderWidth={'2px'}
      borderColor={isDisabled ? 'whiteAlpha.800' :'green.500'}
      borderStyle={'solid'}
      shadow={uiStyles.cardShadow}
    >
      <UiStack
        transform={`scale(${isDisabled ? 1 : 0.99})`}
        transition={'all .2s ease-in-out'}
        alignItems={'stretch'}
        spacing={8}
      >
        <UiHStack spacing={8} flexGrow={1} alignItems={'flex-end'}>
          <UiStack minW={32} maxW={32}>
            <UiText>Hour rate</UiText>
            <UiText>
              <PriceDisplay price={subscription.servicePack.hourPrice} suffix={' / hour'}/>
            </UiText>
          </UiStack>
          <UiSlider
            isDisabled={isDisabled}
            value={subscription.plan.servicePack.hours}
            min={40}
            max={300}
            step={20}
            minWidth={800}
            onChange={(val) => onHoursUpdate(val)}
          >
            <UiSliderMark
              value={isDisabled ? 40 : subscription.plan.servicePack.hours} // Use 40 when disabled will make the 'N/A' text visible.
              textAlign='center'
              color={'gray.800'}
              // fontWeight={600}
              mt={-12}
              ml={-16}
              w={32}
            >
              {!isDisabled ? (
                <UiText>
                  {subscription.plan.servicePack.hours} hours
                </UiText>
              ) : (
                <UiText color={'gray.400'}>
                  0 hour
                </UiText>
              )}
            </UiSliderMark>
            <UiSliderTrack bg={'gray.300'} height={2} borderRadius={uiStyles.borderRadius}>
              <UiSliderFilledTrack bg='green.500' />
            </UiSliderTrack>
            <UiSliderThumb boxSize={6} autoFocus={false} bgColor={'green.500'}/>
          </UiSlider>
          <UiStack minW={24} maxW={24} alignItems={'flex-end'}>
            <UiText>Total price</UiText>
            <UiText>
              <PriceDisplay price={calculateServicePackPrice(subscription)}/>
            </UiText>
          </UiStack>
          <UiStack justifyContent={'center'} height={'100%'}>
            <UiHStack justifyContent={'flex-end'} alignItems={'center'}>
              <UiStack>
                <UiSwitch
                  colorScheme={'green'}
                  size={'md'}
                  isChecked={!isDisabled}
                  onChange={onToggle}
                />
              </UiStack>
            </UiHStack>
          </UiStack>
        </UiHStack>
        {/*<BaseDividerHorizontal height={0}/>*/}
        {/*<UiHStack justifyContent={'flex-end'} alignItems={'center'}>*/}
        {/*  <UiStack>*/}
        {/*    <UiSwitch*/}
        {/*      colorScheme={'green'}*/}
        {/*      size={'lg'}*/}
        {/*      isChecked={!isDisabled}*/}
        {/*      onChange={onToggle}*/}
        {/*    />*/}
        {/*  </UiStack>*/}
        {/*</UiHStack>*/}
      </UiStack>
    </UiStack>
  );
};

export default ServicePackEditor;