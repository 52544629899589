import { type FC, memo, useCallback } from 'react';
import { UiBox, type UiInputProps, UiStack } from '@/lib/ui';
import { SketchPicker } from 'react-color';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import BaseFormInputField from '@/base/Form/InputField';
import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { type FormikProps } from 'formik';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { type TenantFormData } from '.';

type IFormData = TenantFormData & Record<string, string | number | boolean>;

export interface IInputProps {
  key: string
  name: string
  label: string
  type?: UiInputProps['type'] | 'color' | 'radio-group'
  layout?: 'stack' | 'inline'
  value?: string | number | boolean
  children?: IInputProps[]
  helperText?: string
  hintText?: string
  isRequired?: boolean
}

interface TenantDetailCardProps {
  title: string
  inputFields: IInputProps[]
  form: FormikProps<IFormData>
}

const RenderRadioGroup = memo(
  ({ field, form }: { field: IInputProps, form: FormikProps<IFormData> }) => {
    return (
      <BaseFormCheckboxListField
        name={field.name}
        label={field.label}
        options={field.children?.map(option => { return { label: option.label, value: option.value }; }) as CheckboxOption[]}
        isMultiple={false}
        helperText={'Choose the registration badges.'}
        isRequired={field?.isRequired ?? false}
      />
    );
  }
);

RenderRadioGroup.displayName = 'RenderRadioGroup';

const RenderColorPicker = memo(
  ({ field, form }: { field: IInputProps, form: FormikProps<IFormData> }) => {
    const handleColorChange = ({ hex }: { hex: string }) => {
      void form.setFieldValue(field.name, hex);
    };

    return (
      <UiStack direction={'row'} key={field.key}>
        <BaseFormInputField
          name={field.name}
          label={field.label}
          maxLength={7}
          isRequired={field?.isRequired ?? false}
          layout={field.layout}
          rightElement={
            <Popover placement="left-end" strategy="fixed">
              <PopoverTrigger>
                <UiBox
                  width="35px"
                  height="35px"
                  background={typeof form?.values?.[field.name] === 'string' ? (form.values[field.name] as string) : '#000000'}
                  borderRadius="md"
                  border="1px"
                  borderColor="gray.200"
                />
              </PopoverTrigger>
              <PopoverContent w={'fit-content'}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <UiStack>
                    <SketchPicker
                      color={(form?.values?.[field.name] as string) ?? '#000000'}
                      onChange={handleColorChange}
                    />
                  </UiStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          }
        />
      </UiStack>
    );
  }
);

RenderColorPicker.displayName = 'RenderColorPicker';

const RenderImage = ({ field, form }: { field: IInputProps, form: FormikProps<IFormData> }) => {
  return <ImageSelectField
    name={field.name}
    label={field.label}
    isRequired={field?.isRequired ?? false}
    {...(field.helperText && { helperText: field.helperText })}
    {...(field.hintText && { hintText: field.hintText })}
  />;
};

const renderInputFields = (inputFields: IInputProps[], form: FormikProps<IFormData>) => {
  return inputFields.map((field) => {
    if (field.type === 'image') {
      return <RenderImage key={field.key} field={field} form={form} />;
    }
    if (field.type === 'radio-group') {
      return <RenderRadioGroup key={field.key} field={field} form={form} />;
    }

    if (field.type === 'color') {
      return <RenderColorPicker key={field.key} field={field} form={form} />;
    }

    if (field.children?.length) {
      return (
        <UiStack direction="row" key={field.key}>
          {field.children.map((childField) => {
            return (
              <BaseFormInputField
                key={childField.key}
                name={childField.name}
                label={childField.label}
                layout={childField.layout}
                isRequired={childField?.isRequired ?? false}
              />
            );
          })}
        </UiStack>
      );
    }

    return (
      <BaseFormInputField
        key={field.key}
        name={field.name}
        label={field.label}
        layout={field.layout}
        isRequired={field?.isRequired ?? false}
      />
    );
  });
};

const TenantDetailCard: FC<TenantDetailCardProps> = ({ title = 'text', inputFields, form }) => {
  const renderedFields = useCallback(() => { return renderInputFields(inputFields, form); }, [inputFields, form]);

  return (
    <>
      {renderedFields()}
    </>
  );
};

export default TenantDetailCard;
