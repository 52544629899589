import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from './PageRow';
import EmailPageForm from './EmailPageForm';

export interface ManageLabelsProps extends UiHStackProps {
}

const EmailPage: FC<ManageLabelsProps> = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Email page'}
        description={'This field controls which text displays for the checkbox that controls whether the user enters a normal or group registration. If left blank, the checkbox will display "I\'m registering for a group".'}
        onEdit={onToggle}
        {...props}
      />
      <EmailPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default EmailPage;
