import { type FC } from 'react';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import {
  loadRegistrationInfoFormResponse,
  type RegistrationInfo,
  type InfoFormResponse,
  InfoFormType,
  type InfoForm,
  type InfoFormByAttendeeCategoryQuery,
  infoFormByAttendeeCategoryQueryKey,
  loadInfoFormByAttendeeCategory,
  infoFormResponseQueryKey,
  type Registration,
} from '@/api/registration';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import GenericInfoForm from './GenericInfoForm';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import InformationList from './InformationList';

interface PersonalInfoSessionProps {
  registration: RegistrationInfo
}

const PersonalInfoSession: FC<PersonalInfoSessionProps> = ({
  registration,
}) => {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } =
    useTenantApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>({
    queryKey: [
      infoFormByAttendeeCategoryQueryKey,
      { registrationId: registration?.id, formType: InfoFormType.PERSONAL },
    ],
    queryFn: async () => {
      return await fetchInfoFormByAttendeeCategory(registration?.id);
    },
    enabled: !!registration && !isApiPreparing,
  });

  async function fetchInfoFormByAttendeeCategory(
    registrationId: number | undefined
  ): Promise<InfoForm> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const params: InfoFormByAttendeeCategoryQuery = {
      formType: InfoFormType.PERSONAL,
      registrationId,
    };
    const result = await loadInfoFormByAttendeeCategory(
      createTenantAdminApiRequest
    )(params);

    return result.item ?? {};
  }

  const personalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [
      infoFormResponseQueryKey,
      { registrationId: registration?.id, type: InfoFormType.PERSONAL },
    ],
    queryFn: async () => {
      return await fetchRegistrationInfoFormResponse(registration?.id);
    },
    enabled: !!registration && !isApiPreparing,
  });

  async function fetchRegistrationInfoFormResponse(
    registrationId: number
  ): Promise<InfoFormResponse> {
    const response = await loadRegistrationInfoFormResponse(
      createTenantAdminApiRequest
    )({
      registrationId,
      type: InfoFormType.PERSONAL,
    });

    return response.item ?? {};
  }

  const toModify = () => {
    onOpen();
  };

  return (
    <UiStack>
      <QueryContainer query={infoFormByAttendeeCategoryQuery}>
        {(infoForm) => {
          return (
            <QueryContainer query={personalInfoQuery}>
              {(personalInfoFormResponse) => {
                return (
                  <UiStack spacing={4}>
                    <UiHStack justifyContent={'space-between'}>
                      <UiText variant={'title'}>Personal information</UiText>
                      {infoForm.config && (
                        <BaseButtonPlainIconEdit onClick={toModify} />
                      )}
                    </UiHStack>
                    {infoForm.config && (
                      <>
                        <InformationList
                          fieldsMetadata={infoForm.config.fieldsMetadata}
                          response={personalInfoFormResponse.response ?? {}}
                        />
                        <FullScreen isOpen={isOpen} onClose={onClose}>
                          <GenericInfoForm
                            infoForm={infoForm}
                            infoFormResponse={personalInfoFormResponse}
                            registration={registration}
                            onSuccess={onClose}
                          />
                        </FullScreen>
                      </>
                    )}
                  </UiStack>
                );
              }}
            </QueryContainer>
          );
        }}
      </QueryContainer>
    </UiStack>
  );
};

export default PersonalInfoSession;
