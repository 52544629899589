import { type FC } from 'react';
import {
  UiBox,
  type UiBoxProps, UiHStack, UiIconChalkboard,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import DigitalPosterCard from '@/base/App/DigitalPosterCard';
import AILiveCaptioningCard from '@/base/App/AILiveCaptioningCard';
import { UiTooltip } from '@/lib/ui/Tooltip';

export interface DigitalPosterProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const DigitalPoster: FC<DigitalPosterProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.DigitalPoster) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <UiTooltip
            label={'Display posters on Kiosk and web'}
            borderRadius={uiStyles.borderRadius}
            p={2}
            px={4}
          >
            <UiBox {...uiStyles.hover}>
              <DigitalPosterCard showDescription={false}/>
            </UiBox>
          </UiTooltip>
          {subscription && (
            <UiStack spacing={0} alignItems={'flex-end'}>
              <UiStack>
                <PriceDisplay price={subscription.products[SubscriptionProduct.DigitalPoster].monthlyPrice * 3} suffix={' / 3 months'} />
              </UiStack>
              <UiHStack>
                <UiText>+</UiText><PriceDisplay price={subscription.products[SubscriptionProduct.DigitalPoster].unitRate} suffix={' / poster'} />
              </UiHStack>
            </UiStack>
          )}
        </UiHStack>
        {/*<BaseDividerHorizontal height={4} />*/}
        {/*<UiText variant='body1' color={'text.secondary'}>Display posters on Kiosk and web</UiText>*/}
      </UiStack>
    </AddonCard>
  );
};

export default DigitalPoster;
