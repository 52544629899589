import { type IInputProps } from './TenantDetailCard';

interface IFormLayout {
  key: string
  label: string
  formLayout: IInputProps[]
}

const tenantInfoLayout: IFormLayout = {
  label: 'Tenant Info',
  key: 'tenantInfo',
  formLayout: [
    {
      key: 'name',
      label: 'Tenant Name',
      name: 'name',
      isRequired: true
    },
    {
      key: 'shortName',
      label: 'Tenant Initials',
      name: 'shortName',
      isRequired: true
    },
    {
      key: 'businessNumberLabel', label: 'Tax Identifier/Name', name: 'businessNumberLabel', isRequired: true
    },
    { key: 'businessNumber', label: 'Company Business Number', name: 'businessNumber', isRequired: true },
  ],
};

const tenantContactDetailsLayout: IFormLayout = {
  key: 'tenantContactDetails',
  label: 'Tenant Contact Details',
  formLayout: [
    {
      key: 'helpEmail',
      label: 'Support Email',
      name: 'helpEmail',
    },
    {
      key: 'emailAccounts',
      label: 'Accounts Email',
      name: 'emailAccounts',
    },
    {
      key: 'phone',
      label: 'Accounts Phone Number',
      name: 'phone',
    },
    {
      key: 'websiteUrl',
      label: 'Website',
      name: 'websiteUrl',
      isRequired: true,
    },
    {
      key: 'twitter',
      label: 'X Profile',
      name: 'twitter',
    },
    {
      key: 'instagram',
      label: 'Instagram Profile',
      name: 'instagram',
    },
    {
      key: 'facebook',
      label: 'Facebook Profile',
      name: 'facebook',
    },
    {
      key: 'linkedin',
      label: 'LinkedIn Profile',
      name: 'linkedin',
    },
  ],
};

const tenantAddressLayout: IFormLayout = {
  key: 'tenantAddress',
  label: 'Tenant Address',
  formLayout: [
    {
      key: 'invoiceName',
      label: 'Invoice Name',
      name: 'invoiceName',
      isRequired: true
    },
    {
      key: 'addressStreet1',
      label: 'Address Line 1',
      name: 'addressStreet1',
      isRequired: true
    },
    {
      key: 'addressStreet2',
      label: 'Address Line 2',
      name: 'addressStreet2',
      isRequired: true
    },
    { key: 'addressCity', label: 'Surburb', name: 'addressCity', isRequired: true },
    { key: 'addressState', label: 'State', name: 'addressState', isRequired: true },
    { key: 'addressPostCode', label: 'Post Code', name: 'addressPostCode', isRequired: true },
    { key: 'addressCountry', label: 'Country', name: 'addressCountry', isRequired: true },
  ],
};

const tenantNomenclatureLayout: IFormLayout = {
  key: 'tenantNomenclature',
  label: 'Tenant Nomenclature',
  formLayout: [
    { key: 'scheduleNickname', label: 'Agenda Label', name: 'scheduleNickname' },
    { key: 'nicknameAccount', label: 'Account Label', name: 'nicknameAccount' },
    { key: 'nicknameProfile', label: 'Profile Label', name: 'nicknameProfile' },
    {
      key: 'nicknameMyAccount',
      label: 'My Account Label',
      name: 'nicknameMyAccount',
    },
    {
      key: 'nicknameMyProfile',
      label: 'My Profile Label',
      name: 'nicknameMyProfile',
    },
    { key: 'clientIdLabel', label: 'Client ID', name: 'clientIdLabel' },
  ],
};

const metaTagsLayout: IFormLayout = {
  key: 'metaTags',
  label: 'Meta Tags',
  formLayout: [
    {
      key: 'metaTitle',
      label: 'Meta Title',
      name: 'metaTitle',
      isRequired: true,
    },
    {
      key: 'metaDescription',
      label: 'Meta Description',
      name: 'metaDescription',
      isRequired: true,
    },
    {
      key: 'metaKeywords',
      label: 'Meta Keywords',
      name: 'metaKeywords',
      isRequired: true,
    },
  ],
};

const integrationsLayout: IFormLayout = {
  key: 'integrations',
  label: 'Integrations',
  formLayout: [
    {
      key: 'googleAnalyticsKey',
      label: 'Google Analytics Key',
      name: 'googleAnalyticsKey',
    },
    {
      key: 'googleTagManagerKey',
      label: 'Google Tag Manager Key',
      name: 'googleTagManagerKey',
    },
    {
      key: 'facebookPixelKey',
      label: 'Facebook Pixel Key',
      name: 'facebookPixelKey',
    },
    {
      key: 'facebookDomainVerificationKey',
      label: 'Facebook Domain Verification Key',
      name: 'facebookDomainVerificationKey',
    },
    {
      key: 'jwtRedirectUrl',
      label: 'JWT Redirect URL',
      name: 'jwtRedirectUrl',
    },
  ],
};

const tenantStylingLayout: IFormLayout = {
  key: 'tenantStyling',
  label: 'Tenant Styling & Appearance',
  formLayout: [
    {
      key: 'primaryColour',
      label: 'Primary Colour',
      name: 'primaryColour',
      type: 'color',
      isRequired: true,
    },
    {
      key: 'secondaryColour',
      label: 'Secondary Colour',
      name: 'secondaryColour',
      type: 'color',
      isRequired: true,
    },
    {
      key: 'colourEmailHeader',
      label: 'Email Header Colour',
      name: 'colourEmailHeader',
      type: 'color',
    },
    {
      key: 'colourEmailFooter',
      label: 'Email Footer Colour',
      name: 'colourEmailFooter',
      type: 'color',
    },
    {
      key: 'colourEventHubBannerColour',
      label: 'Event Hub Banner Colour',
      name: 'colourEventHubBannerColour',
      type: 'color',
    },
  ],
};

const privacyConfiguration: IFormLayout = {
  key: 'privacyConfiguration',
  label: 'Privacy Configuration',
  formLayout: [
    {
      key: 'gdprComplianceOption',
      label: 'GDPR Compliance',
      name: 'gdprCompliance',
      type: 'radio-group',
      children: [
        {
          key: 'gdprComplianceEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'gdprComplianceDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    },
    {
      key: 'cookieBannerHeader',
      label: 'Cookie Banner Header',
      name: 'gdprCookieBannerHeader',
    },
    {
      key: 'cookieBannerText',
      label: 'Cookie Banner Text',
      name: 'gdprCookieBannerText',
    },
    {
      key: 'privacyPolicyUrl',
      label: 'Privacy Policy URL',
      name: 'privacyPolicyLink',
    },
  ],
};

const featureFlagsLayout: IFormLayout = {
  key: 'featureFlags',
  label: 'Feature Flags',
  formLayout: [
    {
      key: 'samlSsoEnabled',
      label: 'Enable SAML SSO',
      name: 'samlSsoEnabled',
      type: 'radio-group',
      children: [
        {
          key: 'samlSsoEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'samlSsoDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    },
    {
      key: 'ivsEnabled',
      label: 'Enable Automated IVS Channel Creation',
      name: 'ivsEnabled',
      type: 'radio-group',
      children: [
        {
          key: 'ivsEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'ivsDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    },
    {
      key: 'tenantStatus',
      label: 'Tenant Status',
      name: 'active',
      type: 'radio-group',
      children: [
        {
          key: 'activeEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'activeDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    },
    {
      key: 'smsNotifications',
      label: 'Enable SMS Notifications',
      name: 'smsNotifications',
      type: 'radio-group',
      children: [
        {
          key: 'smsNotificationsEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'smsNotificationsDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    },
    {
      key: 'eventsHub',
      label: 'Enable Events Hub',
      name: 'dashboard',
      type: 'radio-group',
      children: [
        {
          key: 'dashboardEnabled',
          label: 'Enabled',
          name: 'true',
          value: 'true',
        },
        {
          key: 'dashboardDisabled',
          label: 'Disabled',
          name: 'false',
          value: 'false',
        },
      ],
    }
  ]
};

const tenantImagesLayout: IFormLayout = {
  key: 'tentantImages',
  label: 'Tenant Images',
  formLayout: [
    {
      key: 'logoImage',
      label: 'Logo Image',
      name: 'blackLogo',
      type: 'image',
      helperText: 'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB',
      hintText: 'This is the logo that will appear on the app',
    },
    {
      key: 'faviconImage',
      label: 'Favicon',
      name: 'favicon',
      type: 'image',
      helperText: 'Please upload file under format: ico. Size limit: 16x16 10MB',
      hintText: 'This is the icon that will appear on the browser tab',
    },
    {
      key: 'metaLogoImage',
      label: 'Meta Logo',
      name: 'metaLogo',
      type: 'image',
      isRequired: true,
      helperText: 'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB',
      hintText: 'This is the logo that will appear on the app',
    },
    {
      key: 'appLogoImage',
      label: 'App Logo',
      name: 'appLogo',
      type: 'image',
      isRequired: true,
      helperText: 'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB',
      hintText: 'This is the logo that will appear on the app',
    },
    {
      key: 'emailHeaderBannerImage',
      label: 'Email Header Banner Image',
      name: 'headerBannerImage',
      type: 'image',
      helperText: 'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB',
      hintText: 'This is the logo that will appear on the app',
    }
  ]
};

export default {
  tenantInfoLayout,
  tenantContactDetailsLayout,
  tenantAddressLayout,
  tenantNomenclatureLayout,
  metaTagsLayout,
  integrationsLayout,
  tenantStylingLayout,
  privacyConfiguration,
  featureFlagsLayout,
  tenantImagesLayout,
};
