import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  uiStyles,
  UiText,
  UiLink,
  UiIconArrowSquareOut
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import TicketList from './TicketList';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { TicketType } from '@/api/constant';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { UiUnorderedList } from '@/lib/ui/Text/UiUnorderedList';
import { UiListItem } from '@/lib/ui/Text/UiListItem';
import TaxInclusivePrices from '@/registration/buildEvent/MainEventTickets/TaxInclusivePrices';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import BaseBlockList from '@/base/BlockList';
import SharedStock from '@/registration/component/BuildEvent/SharedStock';

const MainEventTickets: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'mainEventTicket', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'mainEventTickets'}
          />
        )}
        message={'List your event tickets.'}
      >
        <UiStack
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <UiHStack>
            <StepInstruction label={'Instructions'}>
              <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                <UiText variant="h5">
                  Registration types
                </UiText>
                <UiText variant='body1'>
                  <b>Ticket types</b> refer to a specific classification of tickets that are offered for attendees to purchase or obtain entry to (components of) the event.
                </UiText>
                <UiText variant='body1'>
                  You can create up to <b>4 different ticket type pages</b> on your registration form:
                </UiText>
                <UiUnorderedList>
                  <UiListItem>
                    <b>Registration types:</b> eg full Conference admission ticket, Conference day tickets, Webinar ticket
                  </UiListItem>
                  <UiListItem>
                    <b>Function tickets:</b> eg. Welcome reception ticket, Gala dinner ticket
                  </UiListItem>
                  <UiListItem>
                    <b>Workshop ticket:</b> eg Pre-event workshop ticket, Morning workshop ticket
                  </UiListItem>
                  <UiListItem>
                    <b>Tours tickets:</b> eg Site visit tickets
                  </UiListItem>
                </UiUnorderedList>
                <UiText variant='body1'>
                  You can create as many different tickets under each ticket type page as you want. You need to assign at least one Attendee group to each ticket that you create. You also have the option to apply the Discount codes and set the discount amount or percentage for each ticket.
                </UiText>
                <UiText variant='body1'>
                  You can change the label for the ticket types in the 'Other settings, customisations' step, eg if you want to replace 'Registration types' to 'Conference tickets; or 'Workshop' tickets to 'Masterclasses'
                </UiText>
                <UiLink href="https://support.evexus.com/agent/evexus/evexus/knowledge-base/page?articlestatus=published&showAllArticles=true&rootcategoryId=19910000000386449&categoryId=19910000000386449&status=published#Solutions/dv/19910000000288967/en" target={'_blank'}>
                  <UiHStack
                    {...uiStyles.hover}
                    borderRadius={uiStyles.borderRadius}
                    p={2}
                  >
                    <UiText
                      color={'primary.500'}
                    >
                      Learn more about Registration types
                    </UiText>
                    <UiIconArrowSquareOut color={'primary.500'} />
                  </UiHStack>
                </UiLink>
              </UiStack>
            </StepInstruction>
          </UiHStack>
          <BaseDividerHorizontal height={4} />
          <BaseBlockList
            bgColor={'#fff'}
            enableDivider={true}
          >
            <TaxInclusivePrices />
          </BaseBlockList>
          <BaseDividerHorizontal height={4} />
          <SharedStock ticketType={TicketType.Main} />
          <BaseDividerHorizontal height={4} />
          <TicketList ticketType={TicketType.Main} />
        </UiStack>
        <UiHStack justifyContent={'space-between'} p={8}>
          <UiStack flexGrow={1} />
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
              Next
            </UiButton>
          </BaseAclContainer>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default MainEventTickets;
