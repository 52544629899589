import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { UiStack, UiDivider } from '@/lib/ui';
import { type FC } from 'react';
import SideNav from '@/account/component/Layout/SideNav';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import SmartWindow from '@/base/Layout/SmartWindow';
import TenantList from './TenantList';
import { useQuery } from '@tanstack/react-query';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { loadEventWebsiteTenants, tenantListQueryKey, type EventWebsiteTenant } from '@/api/account/tenant';
import { useTenantApi } from '@/account/hook/useTenantApi';
import NewTenantRow from './NewTenantRow';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

const Tenant: FC = () => {
  const { tenantCode } = useTenantRoute();
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();

  const tenantsQuery = useQuery<EventWebsiteTenant[], Error>({
    queryKey: [tenantListQueryKey, { tenantCode }],
    queryFn: async () => {
      const result = await loadEventWebsiteTenants(createTenantAdminApiRequest)();
      return result.items ?? [];
    },
    keepPreviousData: true,
    enabled: isLoaded
  });

  return (
    <>
      <BaseLayout
        smartWindow={<SmartWindow />}
        sideMenu={<SideNav activeNodes={['setup', 'tenant']} />}
        appSwitch={<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false} />} currentAppName={'account'} />}
      >
        <BaseLayoutBody isStandalone={false}>
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'}>
            {!(tenantsQuery?.data?.length ?? 0) && <><NewTenantRow /><UiDivider /></>}
            <UiStack spacing={3} marginBottom={'10px'}>
            </UiStack>
            <QueryContainer query={tenantsQuery}>
              {(data: EventWebsiteTenant[]) => {
                return (
                  <TenantList data={data} />
                );
              }}
            </QueryContainer>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Tenant;
