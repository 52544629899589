import {
  SubscriptionBillState,
  SubscriptionBillType,
} from '@/api/constant/subscriptionBill';
import { type ApiRequest, callApi } from '@/api/globalClient';
import { SubscriptionBillItemData } from '@/api/account/subscriptionBillItem';

export const subscriptionBillQueryKey = 'account.subscriptionBill';
export const subscriptionBillSearchQueryKey = 'account.subscriptionBillSearch';

export interface SubscriptionBillData {
  id: number
  name: string
  description: string
  state: SubscriptionBillState
  type: SubscriptionBillType
  number: number
  totalAmount: number // Cents
  taxAmount: number // Cents
  // subscriptionId: number
  subscriptionTermId: number
  dueAt: string | null
  from: string | null
  to: string | null
  createdAt: string | null
  updatedAt: string | null
}

export interface SubscriptionBillSearchTenantBillsRequest {
  tenantId: number
  state?: SubscriptionBillState
  type?: SubscriptionBillType
  types?: SubscriptionBillType[]
  subscriptionId?: number
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}

export interface SubscriptionBillSearchTenantBillsResponse {
  subscriptionBillList: SubscriptionBillData[]
  page: number
  total: number
  errors: string[]
}

export const searchTenantSubscriptionBills = (createGlobalApiAclRequest: () => ApiRequest) => {
  const apiRequest = createGlobalApiAclRequest();
  return async (
    params: SubscriptionBillSearchTenantBillsRequest
  ): Promise<SubscriptionBillSearchTenantBillsResponse> => {
    apiRequest.endpoint.path = '/api/subscription/search-tenant-subscription-bills';
    apiRequest.payload = params;
    // return defaultSubscription;
    const apiData = await callApi<SubscriptionBillSearchTenantBillsResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    apiData.subscriptionBillList = apiData.subscriptionBillList ?? [];
    return apiData;
  };
};

export interface SubscriptionBillLoadTenantSubscriptionBillRequest {
  tenantId: number
  subscriptionBillId: number
}

export interface SubscriptionBillLoadTenantSubscriptionBillResponse {
  subscriptionBill: SubscriptionBillData
  subscriptionBillItemList: SubscriptionBillItemData[]
  errors: string[]
}

export const loadTenantSubscriptionBill = (createGlobalApiAclRequest: () => ApiRequest) => {
  const apiRequest = createGlobalApiAclRequest();
  return async (
    params: SubscriptionBillLoadTenantSubscriptionBillRequest
  ): Promise<SubscriptionBillLoadTenantSubscriptionBillResponse> => {
    apiRequest.endpoint.path = '/api/subscription/load-tenant-subscription-bill';
    apiRequest.payload = params;
    const apiData = await callApi<SubscriptionBillLoadTenantSubscriptionBillResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    apiData.subscriptionBillItemList = apiData.subscriptionBillItemList ?? [];
    return apiData;
  };
};
