import { type FC } from 'react';
import dayjs from 'dayjs';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { UiStack, UiText, uiStyles, UiStackProps, UiSimpleGrid } from '@/lib/ui';
import { type FieldMetadata } from '@/base/FormBuilder/Field';

interface InformationListProps {
  fieldsMetadata: FieldMetadata[] | undefined
  response: Record<string, any>
}

const InformationList: FC<InformationListProps> = ({ fieldsMetadata, response }) => {
  return (
    <UiStack p={8} py={8} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} shadow={uiStyles.cardShadow}>
      <UiSimpleGrid columns={3} gap={8}>
        {fieldsMetadata
          ?.filter(fieldMetadata => { return fieldMetadata.type !== 'sectionBreak'; })
          ?.map((fieldMetadata) => {
            return (
              <InformationItem
                key={fieldMetadata.id}
                fieldMetadata={fieldMetadata}
                response={response} />
            );
          })}
      </UiSimpleGrid>
    </UiStack>
  );
};

interface InformationItemProps extends UiStackProps {
  fieldMetadata: FieldMetadata
  response: Record<string, any>
}

const InformationItem: FC<InformationItemProps> = ({ fieldMetadata, response, ...props }) => {
  if (fieldMetadata.type === 'input') {
    return (
      <UiStack {...props}>
        <UiText color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.id]}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'select') {
    return (
      <UiStack {...props}>
        <UiText color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.id]}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'date') {
    return (
      <UiStack {...props}>
        <UiText color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.id] ? dayjs(response[fieldMetadata.id] as string).format('DD MMM YYYY') : ''}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'descriptionBlock') {
    return (
      <UiStack {...props}>
        <UiText>{fieldMetadata.description}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'phone') {
    const responseValue = response[fieldMetadata.id];
    const phoneNumber = parsePhoneNumberFromString(`${responseValue}`);
    return (
      <UiStack {...props}>
        <UiText color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{phoneNumber ? phoneNumber.formatInternational() : responseValue}</UiText>
      </UiStack>
    );
  }
  return undefined;
};

export default InformationList;
