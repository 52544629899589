import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import {
  UiStack,
  type UiHStackProps
} from '@/lib/ui';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import { useState, type FC } from 'react';
import * as Yup from 'yup';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
};

interface FormData {
  groupCheckboxLabel: string
};

const formSchema = Yup.object().shape({
  groupCheckboxLabel: Yup.string().nullable()
});

const EmailPageForm: FC<LabelsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { mutate: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result.errors && result.errors.length > 0) {
        setSaveErrors(result.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the message for email.']);
    }
  });

  const submitForm = async (values: FormData) => {
    const { groupCheckboxLabel } = values;
    mutateEventSettings({
      eventId,
      config: {
        pageMetadata: {
          ...eventSettingQuery.data!.pageMetadata,
          email: {
            groupCheckboxLabel
          }
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => {
        return (
          <Formik
            initialValues={{
              groupCheckboxLabel: eventSetting.pageMetadata?.email?.groupCheckboxLabel ?? '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={async (
              values: FormData,
              { setSubmitting }: FormikHelpers<FormData>
            ) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
            }}
          >
            <BaseFormDrawer
              isOpen={isVisible}
              onClose={onClose}
              title={'Email page'}
              size={'xl'}
              isLoading={isLoading}
            >
              {saveErrors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {saveErrors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <BaseFormFieldGroup>
                <BaseFormTextareaField
                  name={'groupCheckboxLabel'}
                  label={'Alternative text'}
                  isRequired={false}
                  maxLength={500}
                  helperText={"This field controls which text displays for the checkbox that controls whether the user enters a normal or group registration. If left blank, the checkbox will display ‘I'm registering for a group’."}
                />
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          </Formik>
        );
      }}
    </QueryContainer>
  );
};

export default EmailPageForm;
