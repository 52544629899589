import { type FC } from 'react';
import { Pie } from 'react-chartjs-2';
import { type ChartData, type ChartOptions, ArcElement, Chart } from 'chart.js';

Chart.register(ArcElement);

const stringToColour = (str: string): string => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};

const options: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: true,
    },
  },
};

const defaultData: ChartData<'pie', number[], string> = {
  datasets: [
    {
      data: [],
      backgroundColor: [],
    },
  ],
  labels: [],
};

interface PieChartProps {
  labels: string[]
  data: number[]
  bgColors?: string[]
}

const PieChart: FC<PieChartProps> = ({ labels, data, bgColors }) => {
  const chartData = {
    ...defaultData,
    labels,
    datasets: [
      {
        data,
        backgroundColor: bgColors ?? labels.map(label => { return stringToColour(label); }),
      },
    ]
  };

  return (
    <Pie data={chartData} options={options} />
  );
};

export default PieChart;
