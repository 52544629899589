import { useQuery } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import {
  capacitiesQueryKey,
  type ICapacitiesParams,
  loadCapacities,
} from '@/api/registration/capacities';

export function useCapacities(params: ICapacitiesParams) {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const queryFn = async () => {
    if (!createTenantAdminApiRequest) {
      throw new Error('Tenant Admin API request is not ready.');
    }
    return await loadCapacities(createTenantAdminApiRequest)(params);
  };

  return useQuery({
    queryKey: [capacitiesQueryKey, 'capacities', params],
    queryFn,
    enabled: !isApiPreparing && !!params,
    cacheTime: 0 // Disable caching
  });
}
