
import { type FC } from 'react';
import {
  UiStack,
} from '@/lib/ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import Term from '@/aaa/manage/TenantInfo/Term';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseBlockList from '@/base/BlockList';

export interface TermListProps {
  tenantId: number
  subscriptionRecord: account.SubscriptionRecord
  terms: account.SubscriptionTermRecord[]
}

const TermList: FC<TermListProps> = ({
  tenantId,
  subscriptionRecord,
  terms,
}) => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { tenant } = useTenantRoute();
  // const { adminAuth } = useAdminAuth();

  const { data: billsData, isLoading, error } = useQuery<account.SubscriptionBillSearchTenantBillsResponse, Error>(
    [account.subscriptionBillSearchQueryKey, {tenantId: tenant?.id, subscriptionId: subscriptionRecord.id}],
    async () => {
      return await account.searchTenantSubscriptionBills(createGlobalApiAclRequest())({
        tenantId,
        subscriptionId: subscriptionRecord.id,
        page: 1,
        orderByField: 'id',
        isOrderedAsc: false,
        limit: 30, // Let's get all the teams at once.
      });
    },
    {
      enabled: !!subscriptionRecord.id,
    }
  );

  if (isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (billsData) {
    return (
      <BaseBlockList
        bgColor={'#fff'}
        enableDivider={true}
      >
        {terms.map((termData) => {
          let termBill = null;
          if (Array.isArray(billsData.subscriptionBillList)) {
            termBill = billsData.subscriptionBillList.find((bill) => {
              return bill.subscriptionTermId === termData.id;
            })
          }
          return (
            <Term term={termData} key={termData.id} termBill={termBill ?? undefined}/>
          );
        })}
      </BaseBlockList>
    );
  }
};

export default TermList;
