import { type FC } from 'react';
import {
  UiBox,
  type UiBoxProps, UiHStack, UiIconGlobe, UiIconTranslate,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import LiveTranslationCard from '@/base/App/LiveTranslationCard';
import MultiLingualSupportCard from '@/base/App/MultiLingualSupportCard';
import { UiTooltip } from '@/lib/ui/Tooltip';

export interface LiveTranslationProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const LiveTranslation: FC<LiveTranslationProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.LiveTranslation) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <UiTooltip
            label={'Enable translation of live streamed presentations'}
            borderRadius={uiStyles.borderRadius}
            p={2}
            px={4}
          >
            <UiBox {...uiStyles.hover}>
              <LiveTranslationCard />
            </UiBox>
          </UiTooltip>
          {subscription && (
            <UiStack spacing={0} alignItems={'flex-end'}>
              <UiStack>
                <PriceDisplay price={subscription.products[SubscriptionProduct.LiveTranslation].monthlyPrice * 3} suffix={' / 3 months'} />
              </UiStack>
              <UiHStack>
                <UiText color={'text.secondary'}>+ usage</UiText>
              </UiHStack>
            </UiStack>
          )}
        </UiHStack>
        {/*<BaseDividerHorizontal height={4} />*/}
        {/*<UiText variant='body1' color={'text.secondary'}>Enable translation of live streamed presentations</UiText>*/}
      </UiStack>
    </AddonCard>
  );
};

export default LiveTranslation;
