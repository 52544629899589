import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { SimpleGrid } from '@chakra-ui/react';
import _ from 'lodash';
import { UiFlex, UiHStack, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import PieChart from './PieChart';
import { useCapacities } from '@/registration/hook/useCapacities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { type OnChangeValue } from 'chakra-react-select';

const Capacities = () => {
  const { tenantCode } = useRegisterRoute();
  const [eventId, setEventId] = useState<string>('');
  const [eventOptions, setEventOptions] = useState<SelectElementOption[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [pagination, setPagination] = useState<{ pageIndex: number, pageSize: number }>({ pageIndex: 0, pageSize: 10 });
  const { data: eventList, isLoading: isLoadingEventList } = useEventListQuery({ pagination });
  const { data } = useCapacities({ eventId, filter });

  const chartData = useMemo(() => {
    if (_.isEmpty(data?.item?.data)) return [];
    return (data?.item?.data ?? []).map((item) => { return item.value; });
  }, [data]);

  const chartLabel = useMemo(() => {
    if (_.isEmpty(data?.item?.data)) return [];
    return (data?.item?.data ?? []).map((item) => { return item.label; });
  }, [data]);

  const filterOptions = useMemo(() => {
    if (!data) return [];
    const filters: Record<string, string> = data?.item?.filters ?? {};
    return Object.entries(filters).map(([value, label]) => {
      if (!value) value = '';
      return {
        label,
        value,
      };
    });
  }, [data]);

  useEffect(() => {
    if (eventList?.items && Array.isArray(eventList?.items)) {
      const options = eventList.items.map((event: { id: string, name: string }) => {
        return {
          value: event.id,
          label: event.name,
        };
      });
      setEventOptions([...eventOptions, ...options]);
    }
  }, [eventList]);

  const handleChangeEvent = useCallback(
    (option: OnChangeValue<SelectElementOption, false>) => {
      setEventId(option?.value?.toString() ?? '');
    },
    []
  );

  const handleFilter = useCallback(
    (option: OnChangeValue<SelectElementOption, false>) => {
      setFilter(option?.value?.toString() ?? '');
    },
    []
  );

  const handleScrollToBottom: ((event: WheelEvent | TouchEvent) => void) | undefined = async () => {
    if (eventList?.pagination?.totalPages && pagination.pageIndex + 1 < eventList?.pagination?.totalPages) {
      setPagination((prev: { pageIndex: number, pageSize: number }) => { return { ...prev, pageIndex: prev.pageIndex + 1 }; });
    }
  };
  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['reports', 'capacities']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody isStandalone={false} >
        <UiHStack justifyContent='flex-start' spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Capacities</UiText>
        </UiHStack>
        <UiStack mt={4}>
          <UiSimpleGrid columns={4} spacing={8}>
            <UiStack flexGrow={1} >
              <BaseSimpleSelectElement
                optionValue={eventId}
                onChange={handleChangeEvent}
                options={eventOptions}
                onScrollToBottom={handleScrollToBottom}
                placeholder={'Select event'}
                isLoading={isLoadingEventList}
              />
            </UiStack>
            <UiStack flexGrow={1} >
              <BaseSimpleSelectElement
                optionValue={filter}
                onChange={handleFilter}
                options={filterOptions}
                placeholder={'Select filter'}
              />
            </UiStack>
          </UiSimpleGrid>
        </UiStack>
        <BaseDividerHorizontal height={8} />
        <UiStack height={4} />
        <SimpleGrid columns={{ '2xl': 2, base: 1 }} spacing={8}>
          <UiStack
            alignItems={'stretch'}
            spacing={4}
            borderWidth={'1px'}
            borderColor={'blackAlpha.100'}
            borderRadius={uiStyles.borderRadius}
            p={8}
          >
            <PieChart labels={chartLabel} data={chartData} />
          </UiStack>
        </SimpleGrid>
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default Capacities;
