import { type FC } from 'react';
import {
  UiBox,
  type UiBoxProps, UiButton, UiHStack, UiIconHardDrives, UiIconQuestion,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import ContentHubCard from '@/base/App/ContentHubCard';
import { UiTooltip } from '@/lib/ui/Tooltip';

export interface ContentHubProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const ContentHub: FC<ContentHubProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.ContentHub) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <UiTooltip
            label={'Allow attendees to consume recorded content at their convenience'}
            borderRadius={uiStyles.borderRadius}
            p={2}
            px={4}
          >
            <UiBox {...uiStyles.hover}>
              <ContentHubCard />
            </UiBox>
          </UiTooltip>
          {subscription && (
            <PriceDisplay price={subscription.products[SubscriptionProduct.ContentHub].monthlyPrice * 3} suffix={' / 3 months'} />
          )}
        </UiHStack>
        {/*<BaseDividerHorizontal height={4} />*/}
        {/*<UiText variant='body1' color={'text.secondary'}>Allow attendees to consume recorded content at their convenience</UiText>*/}
      </UiStack>
    </AddonCard>
  );
};

export default ContentHub;
