import {
  type ApiRequest,
  type ApiResponseSingle,
  properCallApi,
} from '../tenantClient';

export const capacitiesQueryKey = 'registration.report.capacities';

export interface ICapacitiesParams {
  filter?: string
  eventId: string | number
}

interface ICapacitieItem {
  label: string
  value: number
}

export interface ICapacitiesRes {
  filters: Record<string, string>
  data: ICapacitieItem[]
}

export function loadCapacities(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (params: ICapacitiesParams): Promise<ApiResponseSingle<ICapacitiesRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/capacities';
    request.endpoint.query = {
      filter: params.filter,
      event_id: params.eventId,
    };
    return await properCallApi<ApiResponseSingle<ICapacitiesRes>>(request);
  };
}
