import { type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import { type OptionBase } from 'chakra-react-select';
import {
  UiFormControl,
  UiFormLabel,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';
import ImageSelect from '../ImageSelect/ImageSelect';
import { type FileOrUrl } from '../ImageSelect/ImageSelectItem';

export interface Option extends OptionBase {
  value: string | number | boolean
  label: string
}

export interface ImageSelectProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  isMultiple?: boolean
  helperText?: string
  hintText?: string
  isRequired?: boolean
  disabled?: boolean
}

const ImageSelectField: FC<ImageSelectProps> = ({
  label,
  name,
  isMultiple = false,
  helperText = undefined,
  hintText = undefined,
  isRequired = true,
  layout = 'inline',
  disabled = false
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1}>
            <FieldContainer
              layout={layout}
            >
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'}>
                <ImageSelect
                  disabled={disabled}
                  {...field}
                  isMultiple={isMultiple}
                  onChange={(value: FileOrUrl[]) => { void form.setFieldValue(name, value); } } />
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!hintText && (
                  <UiFormHelperText>
                    {hintText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      } }
    </Field>
  );
};

export default ImageSelectField;
